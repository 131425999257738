import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/Loading";

const WhoAreYou = lazy(() => import("./pages/commons/WHOAREYOU"));

const Checkout = lazy(() => import("./components/Checkout"));
const CheckoutCancel = lazy(() => import("./components/Checkout/Cancel"));
const CheckoutSuccess = lazy(() => import("./components/Checkout/Success"));

export default function App() {
  return <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {/* NOT FOUNDED PAGE OR ROOT PAGE ! */}
          <Route exact path="/" element={<WhoAreYou />} />
          <Route path="*" element={<WhoAreYou />} />

          {/* Paiement */}
          <Route path="/checkout">
            <Route index element={<Checkout />} />
            <Route path="success" element={<CheckoutSuccess />} />
            <Route path="cancel" element={<CheckoutCancel />} />
          </Route>

        </Routes>
      </BrowserRouter>
  </Suspense>
}